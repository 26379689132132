import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FileUploadModule,
  FileDropDirective,
  FileSelectDirective
} from 'ng2-file-upload';

import { CoreModule, ForbeholdModalService } from '@pfa/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, FileUploadModule, CoreModule],
  declarations: [],
  providers: [ForbeholdModalService],
  exports: [FileDropDirective, FileSelectDirective]
})
export class MyCommonModule {}
